<template>
  <v-app>
    <v-app-bar dark elevation="30" fixed app>
      <v-icon class="attention" aria-label="Back Button" large @click="navigate"
        >mdi-bullseye-arrow
      </v-icon>
      <v-toolbar-title class="attention">
        <v-btn
          x-large
          plain
          to="/"
          aria-label="Home Button"
          v-text="main.title"
        ></v-btn>
      </v-toolbar-title>
      <v-spacer />
      <div class="text-center d-none d-sm-flex">
        <v-tooltip v-for="(item, i) in navigation" :key="i" bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                :to="item.to"
                :title="item.title"
                class="mx-2 nav-icon"
                :aria-label="item.title"
                fab
                dark
                x-small
              >
                <v-icon dark v-text="item.icon"></v-icon>
              </v-btn>
            </span>
          </template>
          <span class="nav-icon" v-text="item.title"> </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              <v-btn
                title="Dark mode"
                class="mx-2 nav-icon"
                fab
                dark
                x-small
                aria-label="Toggle Dark Mode"
                @click="toggleDarkMode"
              >
                <v-icon>mdi-invert-colors</v-icon></v-btn
              ></span
            >
          </template>
          <span
            v-text="`Dark mode: ${this.$vuetify.theme.dark ? 'aan' : 'uit'}`"
          ></span>
        </v-tooltip>

        <!--        <v-menu v-if="user" top :close-on-click="closeOnClick">-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-btn-->
        <!--              class="mx-2 nav-icon"-->
        <!--              fab-->
        <!--              dark-->
        <!--              x-small-->
        <!--              v-bind="attrs"-->
        <!--              v-on="on"-->
        <!--            >-->
        <!--              &lt;!&ndash;              <v-icon dark v-text="'mdi-account'"></v-icon>&ndash;&gt;-->
        <!--            </v-btn>-->
        <!--          </template>-->

        <!--          <v-list dark>-->
        <!--            <v-list-item>-->
        <!--              <v-list-item-title>-->
        <!--                <v-btn x-small block @click="$router.push('/auth/settings')"-->
        <!--                  >Settings</v-btn-->
        <!--                >-->
        <!--              </v-list-item-title>-->
        <!--            </v-list-item>-->
        <!--            <v-list-item>-->
        <!--              <v-list-item-title>-->
        <!--                <v-btn x-small block @click="signOut"-->
        <!--                  >Log uit {{ user.email }}</v-btn-->
        <!--                >-->
        <!--              </v-list-item-title>-->
        <!--            </v-list-item>-->
        <!--          </v-list>-->
        <!--        </v-menu>-->
      </div>
      <v-btn
        class="d-flex d-sm-none"
        icon
        aria-label="Toggle Menu Button"
        @click.stop="main.rightDrawer = !main.rightDrawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-col v-if="alerts.length">
        <v-alert
          v-for="(alert, key) in alerts"
          :key="key"
          border="left"
          close-label="Sluit Alert"
          dense
          :dismissible="alert.dismissible"
          text
          :type="alert.type"
        >
          <h3>{{ alert.title }}</h3>
          <p class="small">{{ alert.content }}</p>
        </v-alert>
      </v-col>
      <transition name="page" mode="out-in">
        <v-container class="content-holder">
          <nuxt />
        </v-container>
      </transition>
    </v-main>
    <v-navigation-drawer
      v-model="main.rightDrawer"
      :right="main.right"
      temporary
      fixed
    >
      <v-list dense flat>
        <v-subheader>Navigatie</v-subheader>
        <v-list-item-group v-model="main.selectedItem" color="primary">
          <v-list-item v-for="(item, i) in navigation" :key="i" :to="item.to">
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item aria-label="Toggle Dark Mode" @click="toggleDarkMode">
            <v-list-item-icon>
              <v-icon v-text="`mdi-invert-colors`"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                v-text="
                  `Dark mode: ${this.$vuetify.theme.dark ? 'aan' : 'uit'}`
                "
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <CookieConsent />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

import { contact, main, navigation } from '~/assets/site-data'
import CookieConsent from '~/components/CookieConsent'

export default {
  components: { CookieConsent },
  data() {
    return {
      contact,
      main,
      navigation,
      closeOnClick: true,
    }
  },
  computed: {
    ...mapGetters(['alerts', 'markets', 'user']),
  },
  async created() {
    await this.$store.dispatch('FETCH_SITE_DATA')
    const theme = localStorage.getItem('theme')
    this.$vuetify.theme.dark = theme === 'dark'
  },
  mounted() {
    window.addEventListener('beforeunload', this.closeConfirmation)
  },
  methods: {
    closeConfirmation(e) {
      e.returnValue = ''
    },
    navigate() {
      this.$router.back()
    },
    toggleDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      localStorage.setItem('theme', this.$vuetify.theme.dark ? 'dark' : 'light')
    },
    signOut() {
      this.$fire.auth.signOut()
    },
    async saveAll() {
      const alerts = JSON.parse(JSON.stringify(this.alerts))
      const markets = JSON.parse(JSON.stringify(this.markets))
      await this.$store.dispatch('SAVE_SITE_DATA', {
        alerts,
        markets,
      })
    },
  },
}
</script>

<style lang="scss">
.page-enter-active,
.page-leave-active {
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
}

.page-enter,
.page-leave-to {
  opacity: 0;
}
.content-holder h3:first-child {
  margin-bottom: 8px;
}
.nav-icon {
  &:hover {
    color: darkorange;
  }
}
.attention:hover {
  color: #fff;
  text-shadow: 0.1px 0.1px 10px #eee, 0.1px 0.1px 10px #ccc;
}
</style>
