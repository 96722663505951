import axios from 'axios'
import { alerts, markets } from '~/assets/site-data'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

const state = () => ({
  user: null,
  markets: [],
  alerts: [],
  isLoading: false,
  settings: {
    selected: '',
    expanded: [],
  },
})

const getters = {
  user: (state) => state.user,
  isLoading: (state) => state.isLoading,
  markets: (state) => state.markets.filter((market) => market.items.length),
  allMarkets: (state) =>
    state.markets
      .flatMap((market) => [...market.items])
      .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : 0)),
  alerts: (state) => state.alerts,
  settings: (state) => state.settings,
}

const actions = {
  // onAuthStateChangedAction(state, { authUser, claims }) {
  //   if (!authUser) {
  //     // remove state
  //     state.commit('SET_USER', null)
  //
  //     // redirect from here
  //     this.$router.push({
  //       path: '/auth/signin',
  //     })
  //   } else {
  //     const { uid, email } = authUser
  //     state.commit('SET_USER', {
  //       uid,
  //       email,
  //     })
  //   }
  // },
  //
  // setLoading({ commit }, loading) {
  //   commit('SET_LOADING', loading)
  // },

  FETCH_SITE_DATA({ commit }) {
    commit('SET_ALERTS', alerts)
    markets.forEach((town) => {
      town.items = town.items.filter((market) => {
        const date = new Date(market.date)
        date.setHours(23)
        date.setMinutes(59)
        date.setSeconds(59)
        return date >= new Date()
      })
    })
    commit('SET_MARKETS', markets)
  },

  // async FETCH_SITE_DATA_FIREBASE({ commit }) {
  //   const loaded = [false, false]
  //   this.commit('SET_LOADING', true)
  //   await this.$fire.firestore
  //     .collection('bullseye')
  //     .doc('alerts')
  //     .onSnapshot((doc) => {
  //       loaded[0] = true
  //       commit('SET_ALERTS', doc.data().alerts)
  //     })
  //
  //   await this.$fire.firestore
  //     .collection('bullseye')
  //     .doc('markets')
  //     .onSnapshot((doc) => {
  //       loaded[1] = true
  //       commit('SET_MARKETS', doc.data().markets)
  //     })
  //   const interval = setInterval(() => {
  //     const isLoaded = loaded.filter(Boolean)
  //     if (isLoaded.length === 2) {
  //       clearInterval(interval)
  //       this.commit('SET_LOADING', false)
  //     }
  //   }, 1000)
  // },

  // async SAVE_SITE_DATA({ state }) {
  //   const alerts = JSON.parse(JSON.stringify(state.alerts))
  //   const markets = JSON.parse(JSON.stringify(state.markets))
  //   await this.$fire.firestore
  //     .collection('bullseye')
  //     .doc('alerts')
  //     .set({ alerts })
  //     .then(() => {
  //       // eslint-disable-next-line no-console
  //       console.log('Document alerts written', alerts)
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line no-console
  //       console.error('Error adding document: ', error)
  //     })
  //   await this.$fire.firestore
  //     .collection('bullseye')
  //     .doc('markets')
  //     .set({ markets })
  //     .then(() => {
  //       // eslint-disable-next-line no-console
  //       console.log('Document markets written', markets)
  //     })
  //     .catch((error) => {
  //       // eslint-disable-next-line no-console
  //       console.error('Error adding document: ', error)
  //     })
  // },

  // ADD_MARKET({ commit }, key) {
  //   const market = {
  //     title: '',
  //     date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  //       .toISOString()
  //       .substr(0, 10),
  //     full: false,
  //   }
  //   commit('ADD_MARKET', { market, key })
  // },

  // DELETE_MARKET({ commit }, key) {
  //   commit('DELETE_MARKET', key)
  // },

  // ADD_ALERT({ commit }) {
  //   const alert = {
  //     title: '',
  //     content: '',
  //     type: 'info',
  //     dismissible: true,
  //   }
  //   commit('ADD_ALERT', alert)
  // },

  // TOGGLE_SLIDE({ commit }, idx) {
  //   commit('TOGGLE_SLIDE', idx)
  // },

  async SAVE_SUBSCRIPTION({ commit }, payload) {
    return await axios.post(
      '/index.php/form',
      // 'http://robinhoodkofferbakmarkt.nl/index.php/form',
      payload
    )
  },
}

const mutations = {
  SET_ALERTS: (state, alerts) => (state.alerts = alerts),
  SET_MARKETS: (state, markets) => (state.markets = markets),
  // SET_LOADING: (state, loading) => {
  //   return (state.isLoading = !!loading)
  // },
  // SET_USER: (state, user) => (state.user = user),
  //
  // ADD_MARKET: (state, { key, market }) => state.markets[key].items.push(market),
  //
  // ADD_ALERT: (state, alert) => state.alerts.push(alert),
  //
  // DELETE_ALERT: (state, idx) => state.alerts.splice(idx, 1),
  //
  // DELETE_MARKET: (state, { marketIdx, idx }) =>
  //   state.markets[marketIdx].items.splice(idx, 1),
  //
  //
  // TOGGLE_SLIDE: (state, idx) =>
  //   (state.settings.expanded[idx] = !this.settings.expanded[idx]),
  //
  // ALERT_UPDATE_TITLE: (state, { text, idx }) =>
  //   (state.alerts[idx].title = text),
  //
  // ALERT_UPDATE_CONTENT: (state, obj) =>
  //   (state.alerts[obj.idx].content = obj.text),
  //
  // ALERT_UPDATE_TYPE: (state, obj) => (state.alerts[obj.idx].type = obj.text),
  //
  // ALERT_UPDATE_DISMISSIBLE: (state, obj) =>
  //   (state.alerts[obj.idx].dismissible = !state.alerts[obj.idx].dismissible),
  //
  // UPDATE_MARKET_TITLE: (state, { $event, marketIdx, idx }) =>
  //   (state.markets[marketIdx].items[idx].title = $event),
  //
  // UPDATE_MARKET_DATE: (state, { $event, marketIdx, key }) =>
  //   (state.markets[marketIdx].items[key].date = $event),
  //
  // UPDATE_MARKET_TEXT: (state, { date, marketIdx, key }) =>
  //   (state.markets[marketIdx].items[key].text = date),
  //
  // UPDATE_MARKET_FULL: (state, { $event, marketIdx, idx }) =>
  //   (state.markets[marketIdx].items[idx].full = !!$event),
}

export default {
  state,
  actions,
  mutations,
  getters,
}
