export default function ({ app, route, redirect }) {
  const authorizedPages = ['/auth/settings', '/auth/signout', '/auth/signin']
  if (authorizedPages.includes(route.path)) {
    // we are on a protected route
    if (!app.$fire.auth.currentUser) {
      // take them to sign in page
      // return redirect('/auth/signin')
      return redirect('/')
    }
  } else if (route.path === '/auth/signin') {
    if (!app.$fire.auth.currentUser) {
      // leave them on the sign in page
    } else {
      return redirect('/auth/settings')
    }
  }
}
