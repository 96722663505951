const year = new Date().getFullYear()
const siteData = {
  main: {
    fixed: false,
    right: true,
    rightDrawer: false,
    title: `Kofferbakmarkten ${year}`,
    selectedItem: 0,
  },
  alertTypes: ['success', 'info', 'warning', 'error'],
  /**
   * Alert Interface:
   *  dismissible: boolean,
   *  type: enum: success', 'info', 'warning', 'error'
   *  title: string
   *  content: string
   */
  alerts: [],
  home: {
    title: 'Kofferbakmarkt Robinhood!',
    subtitle: `
    Robinhood kofferbakmarkten al ${
      year - 2005
    } jaar een begrip in omgeving Zeewolde en Leusden. Grote kofferbakmarkt die altijd vol geboekt is met ruim plek voor 150 a 160 wagens. Nu ook in Nijkerk en Veenendaal. Let op! Entree voor Leusden: na 9:00 uur € 2,- per persoon. Kinderen tot 12 jaar gratis.`,
    market: {
      title: 'Robinhood kofferbakmarkten',
      subtitle:
        'Alle kofferbakmarkt data, klik op een datum om een reminder in je agenda te zetten.',
    },
    seo: {
      keywords:
        'rommelmarkt, kofferbakverkoop, boekenmarkt, vlooienmarkt, markt, tweedehands, antiek, curiosa, kleding, kunst, platen, cd, Leusden, Zeewolde, Nijkerk, Veenendaal',
    },
    button: [
      {
        text: 'Inschrijven',
        to: 'inschrijven',
      },
      {
        text: 'Markt overzicht',
        to: 'markten',
      },
    ],
    images: [
      'caroussel/markt-0.jpg',
      'caroussel/markt-1.jpg',
      'caroussel/markt-2.jpg',
    ],
    algemeen: {
      title: 'Algemene informatie',
      what: {
        title: 'Wat is een Kofferbakmarkt?',
        text: [
          'Het fenomeen kofferbakmarkt is van oorsprong Engels en is qua opzet gelijk aan de bekende uit/vlooienmarkt in Nederland. Alleen staan er bij een vlooienmarkt of vrijmarkt kramen.',
          "Bij de kofferbakmarkt komen de verkopers met auto's naar de locatie toe en verkopen tweede hands en overtollige artikelen vanuit de kofferbak. Een kleed voor de auto of een kleine tafel is toegestaan. Er zijn echter geen kramen.",
        ],
      },
      visit: {
        title: 'De kofferbakmarkt bezoeken',
        text: [
          'Er is van alles te koop op de kofferbakmarkt, met uitzondering van etenswaren. Wat wel mag zijn eigen gekweekte plantjes, groente en fruit, mits niet beroepsmatig.',
          'Bekijk de agenda voor kofferbakmarkten in Leusden, Nijkerk, Veenendaal en Zeewolde. Op de markt kan iedereen een hapje en een drankje doen.',
          'Altijd catering tegen een goede prijs',
        ],
      },
    },
    selectedItem: 0,
    navigation: [
      {
        icon: 'mdi-home',
        ico: 'homeIco',
        title: 'Home',
        to: '/',
      },
      {
        icon: 'mdi-shopping',
        ico: 'shoppingIco',
        title: 'Markten',
        to: '/markten',
      },
      {
        icon: 'mdi-cart',
        ico: 'cartIco',
        title: 'Inschrijven',
        to: '/inschrijven',
      },
      {
        icon: 'mdi-chat-processing',
        ico: 'chatIco',
        title: 'Contact',
        to: '/contact',
      },
      {
        icon: 'mdi-exclamation-thick',
        ico: 'exclamationIco',
        title: 'Regelementen',
        to: '/regelementen',
      },
    ],
  },
  costs: {
    title: 'Kosten',
    subtitle: 'Onderstaand de kosten voor komende periode.',
    button: {
      text: 'Reserveer uw plek',
      to: '/inschrijven',
    },
    tableHeaders: ['Vervoer', 'Prijs'],
    costs: [
      { text: 'Auto: € 15,00', price: '€ 15,00' },
      { text: 'Auto en aanhanger: € 20,00', price: '€ 20,00' },
      { text: 'Bestelbus: € 17,50', price: '€ 17,50' },
      { text: 'Bestelbus / camper en aanhanger: € 22,50', price: '€ 22,50' },
    ],
  },
  rules: {
    title: 'Regelementen',
    subtitle: 'Voor een gezellig uitje voor iedereen',
    alert:
      'Wij wensen u een leuke en gezellige dag toe en zien u graag op volgende markten terug. Namens Robinhood, de marktmeester en zijn crew.',
    main: {
      title: 'Algemeen',
      rules: [
        'Inrijden vanaf 07:00 uur, maximaal 2 personen per voertuig',
        'Uitsluitend verkoop van 2e hands door particuliere deelnemers',
        'Indien er spullen die naar de mening van organisatie, afkomstig zouden kunnen zijn van diefstal/inbraak, zal de politie worden ingelicht',
        'U dient alle adviezen en aanwijzingen van de crew/organisatie op te volgen. Bij niet nakomen kan eventueel verwijdering van de markt geschieden',
        'U moet uw plek schoon en leeg achterlaten',
        'Het is niet toegestaan om de markt zonder toestemming van de organisatie voor 15.00 uur te verlaten',
        'Afval en restanten mogen niet achtergelaten worden en dienen meegenomen te worden. Daarom zal er op bepaalde markten borg dienen te worden betaald, welke na controle zal worden terugbetaald. U ontvangt daarvoor een speciale kwitantie',
      ],
    },
    forbidden: {
      title: 'Het is verboden om:',
      rules: [
        'Open vuur te maken',
        'Muziek ten gehore te brengen',
        'Verkoop van wapens, drugs, rookwaren en/of aanverwante artikelen',
        'Geen nieuwe artikelen te koop aan te bieden, in de ruimste zin',
        'Gokspellen/loterijen aan te bieden/te verkopen',
      ],
    },
  },
  contact: {
    title: 'Contact',
    subtitle:
      'De gezelligste, leukste en grootste kofferbakmarkt van Midden Nederland.',
    content: [
      'De markt bied ruime plaatsen voor het uitstallen van uw verkoopwaar.<br/>',
      'Indien u meer informatie nodig heeft zijn wij te bereiken:<ul><li>via email: <a href="mailto:robinhoodmarkten@gmail.com" target="_blank" rel="noopener noreferrer">robinhoodmarkten@gmail.com</a></li><li>of u kunt bellen: <a href="tel:0031640097528">06-40097528</a></li></ul>',
    ],
    routes: [
      {
        name: 'Nijkerk',
        link: `https://www.google.com/maps/dir//Winkelcentrum+Paasbos,+Hoefslag,+Nijkerk/@52.2151658,5.4554336,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47c64982ce687375:0xd24bc6b4f92f266a!2m2!1d5.4908072!2d52.215058`,
        src: 'https://www.google.com/maps/embed?pb=!4v1649375033270!6m8!1m7!1sl_1WRxlK8tv_m96RvR-juQ!2m2!1d52.21466740849019!2d5.49106246603342!3f248.97298309121024!4f1.6849592477300064!5f0.7820865974627469',
      },
      {
        name: 'Leusden',
        link: `https://www.google.com/maps/place/Kastanjelaan+2,+3833+AN+Leusden/@52.1391985,5.420817,3a,75y,256.82h,99.21t/data=!3m6!1e1!3m4!1s1uSQBlc5YJVk1ADO8Gs_OA!2e0!7i16384!8i8192!4m15!1m8!3m7!1s0x47c645cfb52baa4d:0x57572b257abbdb8f!2sKastanjelaan+2,+3833+AN+Leusden!3b1!8m2!3d52.1398985!4d5.4208839!16s%2Fg%2F11bw4hfhmd!3m5!1s0x47c645cfb52baa4d:0x57572b257abbdb8f!8m2!3d52.1398985!4d5.4208839!16s%2Fg%2F11bw4hfhmd?coh=205409&entry=ttu`,
        src: 'https://www.google.com/maps/embed?pb=!3m2!1sen!2snl!4v1716826042732!5m2!1sen!2snl!6m8!1m7!1s1uSQBlc5YJVk1ADO8Gs_OA!2m2!1d52.13919847100762!2d5.420817009013596!3f256.8207321191012!4f9.210283380718366!5f0.7820865974627469',
      },
      {
        name: 'Zeewolde',
        link: `https://www.google.com/maps/dir//52.3275203,5.5340017/@52.3275181,5.4640354,12z`,
        src: 'https://www.google.com/maps/embed?pb=!4v1649375128024!6m8!1m7!1st5qQ3v-MzchgDl1kz7mr9g!2m2!1d52.3292913635866!2d5.537468836762915!3f337.25684009937623!4f-1.4039683103486595!5f0.7820865974627469',
      },
      {
        name: 'Veenendaal',
        link: `https://www.google.com/maps/dir//Groeneveldselaan+7,+Veenendaal/@52.0202681,5.5261471,13z`,
        src: 'https://www.google.com/maps/embed?pb=!3m2!1sen!2snl!4v1712181629407!5m2!1sen!2snl!6m8!1m7!1s1x7CFlI6JTHg1SaxFmRrhg!2m2!1d52.020043039995!2d5.567315526301698!3f30.752306951879063!4f-3.569569506422482!5f0.7820865974627469',
      },
    ],
  },
  marketInfo: {
    title: 'Markten',
    subtitle:
      'Hieronder vindt u de markten voor komende periode. Dubbelklik op een markt dag in de kalender en zet het vast in de agenda!',
    button: {
      text: 'Reserveer uw plek',
      to: '/inschrijven',
    },
  },
  signOn: {
    title: 'Inschrijven',
    subtitle:
      'Hieronder kunt u zich inschrijven voor de markten komende periode.',
    alert:
      'Indien u binnen 2 dagen geen inrij nummer hebt ontvangen, neem dan contact op met:',
    phone: { tel: 'tel:0031640097528', number: '06-40097528' },
    agreeToTerms: 'Eerder weg of niet opruimen kost je de borg.',
    subscribe: [
      {
        title: 'Markten',
        subtitle: 'Vul markt en het type vervoer in:',
      },
      {
        title: 'Uw gegevens',
        subtitle: 'Vul hieronder uw persoonlijke gegevens in:',
      },
    ],
    button: {
      text: 'Reserveer uw plek',
      to: '/inschrijven',
    },
    selected: [],
    allItems: [],
    markets: [
      {
        title: 'Leusden',
        subtitle:
          'Parkeerterrein ARAG. Kastanjelaan 2, 3833AN Leusden. Vroege vogels vanaf 7:00 € 5,- entree. Na 9:00 € 2,- per persoon. Kinderen tot 12 jaar gratis.',
        items: [
          {
            title: 'Leusden',
            text: 'zondag, 30, juni, 2024',
            date: '2024-06-30',
            dateval: 'leusden_2024-06-30',
            full: false,
            cancelled: false,
          },
          {
            title: 'Leusden',
            text: 'zondag, 21, juli, 2024',
            date: '2024-07-21',
            dateval: 'leusden_2024-07-21',
            full: false,
            cancelled: false,
          },
          {
            title: 'Leusden',
            text: 'zondag, 18, augustus, 2024',
            date: '2024-08-18',
            dateval: 'leusden_2024-08-18',
            full: false,
            cancelled: false,
          },
          {
            title: 'Leusden',
            text: 'zondag, 8, september, 2024',
            date: '2024-09-08',
            dateval: 'leusden_2024-09-08',
            full: false,
            cancelled: false,
          },
          {
            title: 'Leusden',
            text: 'zondag, 29, september, 2024',
            date: '2024-09-29',
            dateval: 'leusden_2024-09-29',
            full: false,
            cancelled: false,
          },
        ],
      },
      {
        title: 'Zeewolde',
        subtitle:
          'Evenementen terrein Horsterweg. Bezoekers zijn welkom vanaf 09:00 uur.',
        items: [
          {
            title: 'Zeewolde',
            text: 'zaterdag, 23, maart, 2024',
            date: '2024-03-23',
            dateval: 'zeewolde_2024-03-23',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 13, april, 2024',
            date: '2024-04-13',
            dateval: 'zeewolde_2024-04-13',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 18, mei, 2024',
            date: '2024-05-18',
            dateval: 'zeewolde_2024-05-18',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 22, juni, 2024',
            date: '2024-06-22',
            dateval: 'zeewolde_2024-06-22',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 13, juli, 2024',
            date: '2024-07-13',
            dateval: 'zeewolde_2024-07-13',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 10, augustus, 2024',
            date: '2024-08-10',
            dateval: 'zeewolde_2024-08-10',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 14, september, 2024',
            date: '2024-09-14',
            dateval: 'zeewolde_2024-09-14',
            full: false,
            cancelled: false,
          },
          {
            title: 'Zeewolde',
            text: 'zaterdag, 5, oktober, 2024',
            date: '2024-10-05',
            dateval: 'zeewolde_2024-10-05',
            full: false,
            cancelled: false,
          },
        ],
      },
      {
        title: 'Nijkerk',
        subtitle:
          'Paasbos, de Hoefslag. Bezoekers zijn welkom vanaf 09:00 uur.',
        items: [
          {
            title: 'Nijkerk',
            text: 'zaterdag, 30, maart, 2024',
            date: '2024-03-30',
            dateval: 'nijkerk_2024-03-30',
            full: false,
            cancelled: false,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 20, april, 2024',
            date: '2024-04-20',
            dateval: 'nijkerk_2024-04-20',
            full: false,
            cancelled: true,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 25, mei, 2024',
            date: '2024-05-25',
            dateval: 'nijkerk_2024-05-25',
            full: false,
            cancelled: false,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 22, juni, 2024',
            date: '2024-06-22',
            dateval: 'nijkerk_2024-06-22',
            full: false,
            cancelled: true,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 20, juli, 2024',
            date: '2024-07-20',
            dateval: 'nijkerk_2024-07-20',
            full: false,
            cancelled: false,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 17, augustus, 2024',
            date: '2024-08-17',
            dateval: 'nijkerk_2024-08-17',
            full: false,
            cancelled: false,
          },
          {
            title: 'Nijkerk',
            text: 'zaterdag, 7, september, 2024',
            date: '2024-09-07',
            dateval: 'nijkerk_2024-09-07',
            full: false,
            cancelled: false,
          },
        ],
      },
      {
        title: 'Veenendaal',
        subtitle:
          'Groeneveldselaan 7, Evenementen terrein Veenendaal. Bezoekers zijn welkom vanaf 09:00 uur.',
        items: [
          {
            title: 'Veenendaal',
            text: 'zaterdag, 21, september, 2024',
            date: '2024-09-21',
            dateval: 'veenendaal_2024-09-21',
            full: false,
            cancelled: true,
          },
        ],
      },
    ],
    valid: false,
    validated: false,
    snackbar: false,
    formValidationMessages: [{ msg: '', type: '' }],
    formSent: false,
    validation: {
      vehicle: [
        (value) => value.text !== 'Selecteer uw vervoer' || 'Verplicht.',
      ],
      markets: [(value) => !!value.length || 'Verplicht.'],
    },
    inputs: [
      {
        name: 'naam',
        placeholder: 'Achternaam',
        value: '',
        validation: [
          (value) => !!value || 'Verplicht.',
          (value) => (value && value.length >= 2) || 'Minimaal 2 karakters',
        ],
      },
      {
        name: 'telefoon',
        placeholder: '0612345678',
        value: '',
        validation: [
          (value) => !!value || 'Verplicht.',
          (value) => (value && value.length >= 10) || 'Minimaal 10 karakters',
          (value) => {
            const pattern = /^\(?([+]31|0031|0)-?6(\s?|-)([0-9]\s{0,3}){8}$/
            return pattern.test(value) || 'Ongeldig nummer.'
          },
        ],
      },
      {
        name: 'email',
        placeholder: 'ik@domein.nl',
        value: '',
        validation: [
          (value) => !!value || 'Verplicht.',
          (value) => {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Ongeldig e-mail adres.'
          },
        ],
      },
      {
        name: 'plaats',
        placeholder: 'Woonplaats',
        value: '',
        validation: [
          (value) => !!value || 'Verplicht.',
          (value) => (value && value.length >= 3) || 'Minimaal 3 karakters',
        ],
      },
    ],
    select: { text: 'Selecteer uw vervoer', price: '€ 0,00' },
  },
}
export const alerts = siteData.alerts
export const alertTypes = siteData.alertTypes
export const main = siteData.main
export const home = siteData.home
export const costs = siteData.costs
export const rules = siteData.rules
export const contact = siteData.contact
export const marketInfo = siteData.marketInfo
export const markets = siteData.signOn.markets
export const signOn = siteData.signOn
export const navigation = siteData.home.navigation
