<template>
  <v-alert
    v-if="cookieMessage"
    color="orange"
    class="fixed-bottom"
    icon="mdi-cookie"
    dark
    border="left"
    prominent
    transition="scale-transition"
  >
    <v-row align="center">
      <v-col class="grow"
        >Wij maken gebruik van cookies.
        <NuxtLink to="/privacy">Lees meer</NuxtLink></v-col
      >
      <v-col class="shrink">
        <v-btn fab color="primary" @click="hideCookieMessage">Ok!</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
export default {
  name: 'CookieConsent',
  data() {
    return {
      cookieMessage: true,
    }
  },
  mounted() {
    const cookie = localStorage.getItem('cookiemessage')
    if (cookie) {
      this.cookieMessage = cookie !== 'hidden'
    }
  },
  methods: {
    hideCookieMessage() {
      localStorage.setItem('cookiemessage', 'hidden')
      this.cookieMessage = false
    },
  },
}
</script>
<style lang="scss">
.fixed-bottom {
  background-color: white;
  position: fixed !important;
  bottom: 0;
  left: 0;
}
</style>
